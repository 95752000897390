import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/Footer.js";
import Navbarinner from "../components/Navbarinner.js";
import { convert } from "../helper/convert.js";
import config from '../config/config.js'
import { useSelector } from "react-redux";
import { toastAlert } from "../helper/toastAlert.js";
import isEmpty from "is-empty";
import { connection } from "../helper/connection.js";
import { numberFloatOnly, toFixedFormatValue } from "../helper/customMath.js";
import Web3 from "web3";
import { Link } from "react-router-dom";
import DefiQIDOABI from '../ABI/DefiqIDO.json'


var initialValues = {
    "tokenprice": ''
}
export default function Admin(props) {
    var ConfirmRef = useRef();
    const [load, setLoad] = useState(false)
    const [Error, setValidError] = useState("")
    const [values, setValues] = useState(initialValues)
    const { tokenprice } = values;
    const walletConnection = useSelector((state) => state.walletConnection);



    const handleChange = async (e) => {
        const { name, value } = e.target
        setValues({ ...values, ...{ [name]: value } })
        setValidError("")
    }

    const handlesubmit = async (e) => {
        e.preventDefault();
        if (isEmpty(tokenprice)) {
            let errors = "Please fill valid token price"
            return setValidError(errors)
        }
        var get = await connection()
        try {
            if (get && get.web3) {
                setLoad(true)
                var address = get.address;
                var web3 = get.web3;
                var contract = new web3.eth.Contract(DefiQIDOABI, config.DefiqIDO)
                let admin = await contract.methods.owner().call();
                // console.log('admaaaaaaaaaaaaain: ', admin);

                // if (address !== config.AdminAddress) {
                if ((address).toLowerCase() !== (admin).toLowerCase()) {
                    setLoad(false)
                    return toastAlert('error', 'Please connect to admin wallet address')
                }
                var amt = parseFloat(tokenprice);
                amt = 1 / amt;

                amt = amt * (10 ** 6);
                amt = amt.toString();
                var checkDeci = amt.split('.')
                if (checkDeci.length === 2) {
                    amt = checkDeci[0]
                }
                amt = await convert(amt)


                // console.log('amtamtamtamt: ', amt);

                // var contract = new web3.eth.Contract(DefiQIDOABI, config.DefiqIDO)
                var data = await contract.methods.settoken(amt.toString()).send({ from: address })
                if (data && data.transactionHash) {
                    setLoad(false)
                    setValues(initialValues)
                    return toastAlert('success', 'Price updated successfully')
                }
                else {
                    setLoad(false)
                    return toastAlert('error', 'Transaction Rejected')
                }
            } else {
                setLoad(false)
                return toastAlert('error', 'Please connect to admin wallet address')
            }

        } catch (err) {
            setLoad(false)
            console.log('err: ', err);
            return toastAlert('error', 'Transaction Rejected')
        }


    }

    const validPositive = (e) => {
        if (
            new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
            (e.target.value = "")
        ) {
            e.preventDefault();
        }
    };



    console.log(Error, 'tokenpricetokenpricetokenprice')
    return (
        <div>
            <Navbarinner />

            <div className="page_box" >
                <section className="buytoken">
                    <div className="container">
                        <div className="row" >
                            <div className="col-lg-8 col-xl-6 mx-auto">
                                <div className="buytokenbg">
                                    <div className="head mw-100" >
                                        <h2 className="h2tag mb-4">DefiQ-IDO</h2>
                                    </div>

                                    <div className="mb-4">
                                        <label className="mb-2">Token Price :</label>
                                        <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2"
                                            onChange={handleChange}
                                            name="tokenprice"
                                            value={tokenprice}
                                            onInput={validPositive} />
                                        {!isEmpty(Error) ? <span className='errortext'>{Error}</span> : ''}
                                    </div>

                                    <div className="text-center d-flex justify-content-center" >
                                        {
                                            (load == false) && walletConnection && walletConnection.address && walletConnection.address !== '' ?
                                                <button type="button" className='primary_btn' onClick={handlesubmit}>Submit</button>


                                                : (walletConnection.address == '') ? <button className="primary_btn"
                                                    data-bs-toggle="modal" data-bs-target="#connect_wallet_modal">Connect wallet</button>
                                                    : (load == true) ? <button type="button" className='primary_btn' disabled>Processing...please wait</button>
                                                        : ""
                                        }
                                        {/* <button className="primary_btn" >  Buy Now</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
}
